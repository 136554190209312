import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layouts/MataData/MataData";
import { useAlert } from "react-alert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import OrderDetailsSection from "./OrderDetails";
import { clearErrors, createOrder } from "../../actions/orderAction";
import CheckoutSteps from "./CheckoutSteps ";
import { Typography, Button, Divider, Link } from "@material-ui/core";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import { displayMoney } from "../DisplayMoney/DisplayMoney";
import { makeStyles } from "@material-ui/core/styles";
import "./Cart.css";
import { clearCart } from "../../actions/cartAction";
import ScrollToTopOnRefresh from "../Home/ScrollToTop";


const useStyles = makeStyles((theme) => ({
  payemntPage: {
    padding: "1rem 0",
    width: "100%",
    backgroundColor: "white",
    overflow: "hidden",
  },
  paymentPage__container: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  PaymentBox: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "1rem",
      padding: "2rem",
    },
  },
  PaymentHeading: {
    fontWeight: "800",
    marginBottom: "1rem",
    fontSize: "1.5rem",
    textTransform: "uppercase",
  },
  securePayemnt: {
    display: "flex",
    alignItems: "center",
    fontWeight: "300",
    backgroundColor: "#f5f5f5 !important",
    width: "90%",
    padding: "1rem",
    gap: "0.8rem",
    marginBottom: "1rem",
    "& svg": {
      fontSize: "2rem",
    },
  },
  placeOrderBtn: {
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: "500",
    fontSize: "1rem",
    padding: "0.8rem 1rem",
    borderRadius: "0.5rem",
    width: "90%",
    marginLeft: "1rem",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#00000080",
    },
  },
  payemntAmount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    height: "fit-content",
    padding: "1rem 0.5rem 0 0.5rem",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "2rem",
    },
  },
  order_Details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "2rem 0.5rem 2rem 0.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "2rem",
    },
  },
  orderSub_heading: {
    fontWeight: "600",
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  boldDivider: {
    borderBottom: `0.3px solid #3A3E3A`,
    margin: "5px 0",
    width: "99%",
  },
  shipping_Deatils: {
    display: "flex",
    flexDirection: "column",
    width: "98%",
    padding: "1rem 1px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "1rem 2rem",
    },
  },
  shipping_Address: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  shipping_Address_Details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontWeight: "300",
    padding: "10px 0px",
    width: "70%",
  },
  shipping_Address_edit: {
    paddingRigth: "1rem",
    "& svg": {
      fontSize: "1.8rem",
      cursor: "pointer",
      color: "black",
      "&:hover": {
        color: "#ed1c24",
      },
    },
  },
}));

const PaymentComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { error } = useSelector((state) => state.newOrder);

  const [paymentMethod, setPaymentMethod] = useState(""); // State to track payment method

  // Calculate prices from cart items
  let totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  let discountedPrice = cartItems.reduce((acc, item) => acc + (item.DiscountPrice || item.price) * item.quantity, 0);
  let totalDiscount = totalPrice - discountedPrice;
  let finalPrice = discountedPrice;

  const formattedFinalPrice = displayMoney(finalPrice);

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: discountedPrice,
    shippingPrice: 0, // Assume free shipping
    totalPrice: discountedPrice,
  };

 // Razorpay payment handler
const paymentSubmitHandler = async (e) => {
  e.preventDefault();

  if (paymentMethod === "COD") {
    order.paymentInfo = { id: "COD", status: "Pending" };
    dispatch(createOrder(order));
    dispatch(clearCart());
    navigate("/success", { replace: true });
  } else {
    // Razorpay Payment Process
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const amountInPaise = finalPrice * 100;
      const { data } = await axios.post("/api/v1/payment/process", { amount: amountInPaise }, config);

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Ensure this key is set correctly in .env
        amount: data.order.amount,
        currency: "INR",
        name: "Mufa Perfumes",
        description: "Payment for your order",
        order_id: data.order.id,
        handler: async function (response) {
          try {
            // Payment verification
            const verificationResponse = await axios.post("/api/v1/payment/verify", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              amount: amountInPaise,
            });

            if (verificationResponse.data.success) {
              order.paymentInfo = {
                id: response.razorpay_payment_id,
                status: "Paid",
              };
              dispatch(createOrder(order));
              dispatch(clearCart());
              navigate("/paymentsuccess", { replace: true });
            } else {
              alert.error("Payment verification failed. Please try again.");
            }
          } catch (error) {
            console.error("Error during payment verification:", error);
            alert.error("Payment verification failed. Please try again.");
          }
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: shippingInfo.phoneNo,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error during payment processing:", error);
      alert.error(error.message);
    }
  }
};

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, alert, error]);

  return (
    <>
    <ScrollToTopOnRefresh />
      <div className={classes.payemntPage}>
        <CheckoutSteps activeStep={2} />
        <MetaData title={"Payment"} />
        <div className={classes.paymentPage__container}>
          <div className={classes.PaymentBox}>
            <Typography variant="h5" component="h1" className={classes.PaymentHeading}>
              Payment method
            </Typography>
            <Typography variant="subtitle2" gutterBottom className={classes.securePayemnt}>
              <AssuredWorkloadOutlinedIcon />
              Payments are SSL encrypted so that your credit card and payment details stay safe.
            </Typography>

            {/* COD Option */}
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: paymentMethod === "COD" ? "#00000080" : "#000",
                color: "#fff",
                marginBottom: "1rem",
              }}
              onClick={() => setPaymentMethod("COD")}
              disabled={paymentMethod === "Razorpay"}
            >
              Cash on Delivery (COD)
            </Button>

            {/* Online Payment Option */}
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: paymentMethod === "Razorpay" ? "#00000080" : "#000",
                color: "#fff",
              }}
              onClick={() => setPaymentMethod("Razorpay")}
              disabled={paymentMethod === "COD"}
            >
              Pay Online
            </Button>

            {/* Place Order Button */}
            <Button
              variant="contained"
              className={classes.placeOrderBtn}
              fullWidth
              style={{ marginTop: "3rem" }}
              onClick={paymentSubmitHandler}
              disabled={!paymentMethod} // Disable if no payment method is selected
            >
              Place Order
            </Button>
          </div>

          {/* Order Summary */}
          <div className={classes.payemntAmount}>
            <div className="order_summary">
              <h4>
                Order Summary &nbsp; ( {cartItems.length}{" "}
                {cartItems.length > 1 ? "items" : "item"} )
              </h4>
              <div className="order_summary_details">
                <div className="price order_Summary_Item">
                  <span>Original Price</span>
                  <p>{totalPrice}</p>
                </div>
                <div className="discount order_Summary_Item">
                  <span>Discount</span>
                  <p>
                    <del>{totalDiscount}</del>
                  </p>
                </div>
                <div className="delivery order_Summary_Item">
                  <span>Delivery</span>
                  <p>
                    <b>Free</b>
                  </p>
                </div>
                <div className="separator_cart"></div>
                <div className="total_price order_Summary_Item">
                  <div>
                    <h4>Total Price</h4>
                    <p style={{ fontSize: "14px", marginTop: "-10px", color: "#414141" }}>
                      (Inclusive of all taxes)
                    </p>
                  </div>
                  <p>
                    <b>{formattedFinalPrice}</b>
                  </p>
                </div>
              </div>
            </div>

            {/* Shipping and Billing Details */}
            {/* Existing Order Details and Shipping Details */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentComponent;
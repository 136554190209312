import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import CricketBallLoader from "../layouts/loader/Loader";
import MetaData from "../layouts/MataData/MataData";
import { Link, useNavigate } from "react-router-dom";
import { signUp, clearErrors } from "../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import useStyles from "./LoginFromStyle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function Signup() {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [emailExistsError, setEmailExistsError] = useState(""); // Email existence error
  const [areCheckboxesChecked, setAreCheckboxesChecked] = useState({
    checkbox1: false,
    checkbox2: false,
  });
  const [termsAccepted, setTermsAccepted] = useState(false); // State to manage Terms checkbox
  const [termsError, setTermsError] = useState(""); // State to handle error if Terms are not accepted

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { isAuthenticated, error } = useSelector((state) => state.userData);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      alert.success("User Registered Successfully");
      navigate("/account");
    }
  }, [dispatch, isAuthenticated, error, alert, navigate]);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setAvatarPreview(reader.result);
        setAvatar(reader.result);
      };
    }
  };

  const handleShowPasswordClick = () => {
    setShowPassword((prev) => !prev);
  };

  const handleCheckboxChange = (checkboxName) => (event) => {
    setAreCheckboxesChecked((prevState) => ({
      ...prevState,
      [checkboxName]: event.target.checked,
    }));
  };

  const checkEmailExists = async () => {
    try {
      const response = await fetch('/api/v1/users/check-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.exists) {
          setEmailExistsError("This email already exists.");
          return true; // Email exists
        } else {
          setEmailExistsError(""); // Clear error if email does not exist
          return false; // Email does not exist
        }
      } else {
        alert.error("Error checking email existence.");
      }
    } catch (error) {
      console.error('Error checking email:', error);
      alert.error("An error occurred while checking the email.");
    }
    return false; // Default to not existing if there's an error
  };

  const sendOtp = async () => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert.error("Please enter a valid email address.");
      return;
    }

    const emailExists = await checkEmailExists();
    if (emailExists) return; // Stop if email exists

    try {
      const response = await fetch('/api/v1/otp/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        alert.success(data.message);
        setIsOtpSent(true); // Set OTP sent state
        setOtpError(""); // Clear any previous OTP error
      } else {
        alert.error(data.message || "Failed to send OTP.");
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert.error("An error occurred while sending the OTP. Please try again.");
    }
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
    setOtpError(""); // Clear OTP error when user types
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await fetch('/api/v1/otp/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setOtpError(""); // Clear OTP error
        alert.success("OTP verified successfully!"); // Show success message
        await handleSignUpSubmit(); // Proceed with the signup process
      } else {
        setOtpError(data.message || "Invalid OTP or OTP has expired."); // Show OTP error message
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setOtpError("An error occurred while verifying the OTP. Please try again.");
      alert.error("An error occurred while verifying the OTP. Please try again."); // Only show error in case of a failed verification
    }
  };
  

  const resendOtp = async () => {
    setIsOtpSent(false); // Reset OTP sent state
    await sendOtp(); // Call sendOtp to resend OTP
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();

    if (!termsAccepted) {
      setTermsError("You must accept the terms and conditions.");
      return; // Stop submission if terms not accepted
    }

    setLoading(true);

    const formData = new FormData();
    formData.set("name", name);
    formData.set("email", email);
    formData.set("password", password);
    formData.set("avatar", avatar);

    await dispatch(signUp(formData));
    setLoading(false);
  };

  return (
    <>
      <MetaData title={"Sign Up"} />
      {loading ? (
        <CricketBallLoader />
      ) : (
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={handleSignUpSubmit}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h5" component="h1" className={classes.heading}>
              Sign Up for an Account!
            </Typography>
            {emailExistsError && <Typography color="error">{emailExistsError}</Typography>} {/* Email Exists Error */}
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={name}
              onChange={handleInputChange(setName)}
              error={name.length < 4 || name.length > 20}
              helperText={
                name.length < 4 || name.length > 20
                  ? "Name must be between 4 and 20 characters."
                  : ""
              }
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={email}
              onChange={handleInputChange(setEmail)}
              error={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
              helperText={
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
                  ? "Please enter a valid email address."
                  : ""
              }
            />
            <TextField
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              fullWidth
              className={classes.textField}
              value={password}
              onChange={handleInputChange(setPassword)}
              error={password.length < 8}
              helperText={
                password.length < 8 ? "Password must be at least 8 characters." : ""
              }
              InputProps={{
                endAdornment: (
                  <Button
                    variant="outlined"
                    className={classes.showPasswordButton}
                    onClick={handleShowPasswordClick}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </Button>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              fullWidth
              className={classes.textField}
              value={confirmPassword}
              onChange={handleInputChange(setConfirmPassword)}
              error={confirmPassword !== password}
              helperText={
                confirmPassword !== password ? "Passwords do not match." : ""
              }
            />
            {avatarPreview && (
              <img
                src={avatarPreview}
                alt="Avatar Preview"
                style={{ width: "100px", height: "100px" }}
              />
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<CloudUploadIcon />}
              className={classes.uploadButton}
            >
              Upload Avatar
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleAvatarChange}
              />
            </Button>

            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                />
              }
              label="I accept the Terms and Conditions."
            />
            {termsError && <Typography color="error">{termsError}</Typography>} {/* Terms Error */}

            {!isOtpSent ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={sendOtp}
              >
                Send OTP
              </Button>
            ) : (
              <>
                <TextField
                  label="Enter OTP"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  value={otp}
                  onChange={handleOtpChange}
                  error={otpError !== ""}
                  helperText={otpError || "Enter the OTP sent to your email."}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleVerifyOtp}
                >
                  Verify OTP
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submitButton}
                  onClick={resendOtp}
                >
                  Resend OTP
                </Button>
              </>
            )}

            {/* Sign Up Button */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submitButton}
              type="submit"
            >
              Sign Up
            </Button>

            <Grid container justifyContent="center">
              <Grid item>
                <Link to="/login">Already have an account? Sign in</Link>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </>
  );
}

export default Signup;

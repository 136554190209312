import React, { useState } from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./Footer.css";
const footMenu = [
  {
    id: 1,
    title: "Help",
    menu: [
      {
        id: 1,
        link: "Track Order",
        path: "/orders",
      },
      {
        id: 2,
        link: "FAQs",
        path: "/terms/conditions",
      },

      {
        id: 3,
        link: "Cancel Order",
        path: "/policy/return",
      },
      {
        id: 4,
        link: "Return Order",
        path: "/policy/return",
      },
      {
        id: 5,
        link: "Warranty Info",
        path: "/policy/Terms",
      },
    ],
  },
  {
    id: 2,
    title: "Policies",
    menu: [
      {
        id: 1,
        link: "Return Policy",
        path: "/policy/return",
      },
      {
        id: 2,
        link: "Security",
        path: "/policy/privacy",
      },
      {
        id: 3,
        link: "Sitemap",
        path: "/policy/Terms",
      },
      {
        id: 4,
        link: "Privacy Policy",
        path: "/policy/privacy",
      },
      {
        id: 5,
        link: "T&C",
        path: "/terms/conditions",
      },
    ],
  },
  {
    id: 3,
    title: "Company",
    menu: [
      {
        id: 1,
        link: "About Us",
        path: "/about",
      },
      {
        id: 2,
        link: "Contact Us",
        path: "/contact",
      },
      {
        id: 3,
        link: "Service",
        path: "/",
      },
      {
        id: 4,
        link: "Careers",
        path: "/",
      },
      {
        id: 5,
        link: (
          <a 
            href="https://blogmufaworld.com/"  // Ensure this is the correct link
            target="_blank" 
            rel="noopener noreferrer"
          >
            Blog
          </a>
        ),
        path: "", // Keep path empty since it's not used for external links
      },
    ],
  },
];

 const footSocial = [
   {
     id: 1,
     icon: <FacebookIcon className="facebook_icon" fontSize="large" />,
     path: "https://www.facebook.com/mufaperfumes/",
   },
   {
     id: 2,
     icon: <TwitterIcon className="twitter_icon" fontSize="large" />,
     path: "https://x.com/mufaperfumes",
   },
   {
     id: 3,
     icon: <InstagramIcon className="insta_icon" fontSize="large" />,
     path: "https://www.instagram.com/mufaperfumes/",
   },
   {
     id: 4,
     icon: <LinkedInIcon className="likedin_icon" fontSize="large" />,
     path: "https://in.linkedin.com/in/mufa-international-62a102293",
   },
 ];



const Footer = () => {
  const [subValue, setSubValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubValue("");
    alert("Thankyou, you are subscribed to receive our daily newsletter");
  };

  const currYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="wrapper_footer footer_wrapper ">
            <div className="foot_about foot1">
              <div className="foot_logo">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <img
                    src={require("../../../Image/Footer/logo.png")}
                    alt="Mufa Perfume"
                  />
                  {/* <h1 className="Foot_heading">Mufa Perfumes</h1> */}
                </Link>
              </div>

               <div className="foot_subs">

               <p className="text-lg font-semibold mt-4 text-center leading-tight">
                  Mufa Perfumes, established in 2013, offers a unique blend of cool, refreshing, and long-lasting fragrances. Designed for elegance and sophistication, Mufa Perfumes are perfect for those who seek a signature scent that lasts all day. Discover the perfect fragrance with Mufa Perfumes today.
                </p>

               {/* <h5>Newslatter</h5>
                <form onSubmit={handleSubmit} className="foot_form">
                  <input
                    type="email"
                    className="input_field_footer"
                    placeholder="Email Address*"
                    required
                    value={subValue}
                    onChange={(e) => setSubValue(e.target.value)}
                  />
                  <p>
                    By submitting your email address you agree to the{" "}
                    <Link to="/terms/conditions" className="foot_subs_text">
                      Terms & Conditions
                    </Link>
                  </p>
                  <button type="submit" className="btnFooter">
                    Subscribe
                  </button>
                </form>*/}
              </div> 
            </div>

            <div className="foot_menu_container">
              {footMenu.map((item) => {
                const { id, title, menu } = item;
                return (
                  <div className="foot_menu foot2" key={id}>
                    <h4>{title}</h4>
                    <ul>
                      {menu.map((item) => {
                        const { id, link, path } = item;
                        return (
                          <li key={id}>
                            <Link to={path}>{link}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>

            <div className="foot_links foot3">
               {/*  <div className="foot_dowload_appLink">
               <h5>Download app</h5>
                  
                <div className="app_links foot_policyLink">
                  <span>
                <Link to="/policy/privacy">
                      <p className="foot_policyLink_p">Privacy Policy</p>
                    </Link>
                    </span>
                    <span>
                    <Link to="/terms/conditions">
                      <p className="foot_policyLink_p">TERMS & CONDITIONS</p>
                    </Link>
                    </span>
                    <span>
                    <Link to="/policy/Terms">
                      <p className="foot_policyLink_p">TERMS OF USE</p>
                    </Link>
                    </span>
                  
                </div>
                
              </div> */}

              <div className="foot_social">
                {footSocial.map((item) => {
                  const { id, icon, path } = item;
                  return (
                    <a
                      href={path}
                      key={id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {icon}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="separatorFooter bg-[#d4a24c]"></div>

        <div className="sub_footer_root bg-[#d4a24c]">
          <div className="container_Footer ">
            <div className="sub_footer_wrapper ">
            <div className="foot_copyright pt-2.5 ">
                <p>
                  <span className="text-white">Copyright &copy; {currYear}</span> | <a href="https://mufaworld.com/" className="text-blue-500">MUFA INTERNATIONAL PVT. LTD.</a>
                  <span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

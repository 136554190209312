import React, { useState, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";
import "./FeatureSlider.css";

const HeroSlider2 = ({ interval = 5000 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  // Resize event with debounce
  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth <= 768);
    }, 300);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const slides = useMemo(() => [
    {
      id: 1,
      desktopImage: require("../../Image/Cricket-wepon/the_perfect_scent_for_every_story_you_tell1300 x 437 Desktop.webp"),
      mobileImage: require("../../Image/Cricket-wepon/the_perfect_scent_for_every_story_you_tell800 x 1070 Mobile.webp"),
    },
    {
      id: 2,
      desktopImage: require("../../Image/Cricket-wepon/unveil_the_essence_of_luxury_on_every_purchase1300 x 437Desktop.webp"),
      mobileImage: require("../../Image/Cricket-wepon/unveil_the_essence_of_luxury_on_every_purchase800 x 1070mobile.webp"),
    },
    {
      id: 3,
      desktopImage: require("../../Image/Cricket-wepon/on_every_purchase_flat_30_off1300 x 437 Desktop.webp"),
      mobileImage: require("../../Image/Cricket-wepon/on_every_purchase_flat_30_off800 x 1070 mobile.webp"),
    },
    {
      id: 4,
      desktopImage: require("../../Image/Cricket-wepon/on_every_purhase_embrace_the_esseence1300 x 437 Desktop.webp"),
      mobileImage: require("../../Image/Cricket-wepon/on_every_purhase_embrace_the_esseence800 x 1070 mobile.webp"),
    },
  ], []);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, interval);
    return () => clearInterval(slideInterval);
  }, [interval]);

  useEffect(() => {
    slides.forEach(slide => {
      const img = new Image();
      img.src = isMobile ? slide.mobileImage : slide.desktopImage;
    });
  }, [isMobile, slides]);

  // Handle swipe on mobile
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    } else if (touchStartX - touchEndX < -50) {
      setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    }
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  return (
    <div
      className="hero-slider"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`slide ${index === currentSlide ? "active" : ""}`}
          aria-hidden={index !== currentSlide}
        >
          <img
            src={isMobile ? slide.mobileImage : slide.desktopImage}
            alt={`Slide ${index + 1}`}
            loading="lazy"
          />
        </div>
      ))}
      <div className="indicators">
        {slides.map((_, index) => (
          <button
            key={index}
            className={index === currentSlide ? "active" : ""}
            onClick={() => setCurrentSlide(index)}
            aria-label={`Go to slide ${index + 1}`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default HeroSlider2;

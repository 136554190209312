import React from "react";
import "./Home.css";
import ProductCard from "./ProductCard";
import MataData from "../layouts/MataData/MataData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import HeroSlider2 from "./HeroSilder2";
import FeaturedSlider from "./FeatureSlider";
import Categories from "./Categories";
import SevenSection from "./SevenSection";
import VideoSection from "./VideoSection";
import Testimonials from "./Testimonials";
import Blog from "../Blog/Blog";
import ScrollToTopOnRefresh from "../Home/ScrollToTop";

function Home() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  React.useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  return (
    <>
      <ScrollToTopOnRefresh />
      {loading ? (
        <Loader />
      ) : (
        <>
          <MataData title="Mufa Perfume's" />
          <div className="Home_Page bg-[#001c25]">
            <div className="">
              <HeroSlider2 />
            </div>

            <div className="feature bg-[#001c25]">
              <h2 className="collections-title text-center mb-4 text-[#001c25] py-4">
                FEATURE'S PRODUCT
              </h2>

              {products && <FeaturedSlider products={products} />}
            </div>

            <div>
              <Categories />
            </div>

            <div>
              <SevenSection />
            </div>

            <div>
              <h2 className="collections-title text-center mb-4 text-[#d4a24c]">
                TRENDING PRODUCT
              </h2>
            </div>
            <div className="trending-products bg-[#001c25]">
              {products &&
                products.map((product) => (
                  <ProductCard key={product._id} product={product} />
                ))}
            </div>

            <div>
              <VideoSection />
            </div>
            <div>
              <Testimonials />
            </div>
            <div>
              <Blog />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Home;

// /frontend/src/reducers/shiprocketReducer.js
import { SHIPROCKET_ORDER_SUCCESS, SHIPROCKET_ORDER_FAIL } from '../constants/shiprocketConstants';

export const shiprocketReducer = (state = { order: {}, success: false }, action) => {
  switch (action.type) {
    case SHIPROCKET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        success: true,
      };
    case SHIPROCKET_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};

// Display Money in Indian Format
export const displayMoney = function(num) {
  if (isNaN(num)) return '₹0'; // Return '₹0' if num is not a number
  const numFormat = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return numFormat.format(num).split(".", 1)[0];
};

// Calculate Discount Percentage
export const calculateDiscount = (discountedPrice, originalPrice) => {
  if (originalPrice <= 0) return 0; // Avoid division by zero
  if (isNaN(discountedPrice) || isNaN(originalPrice)) return 0;
  const disCountPercent = ((originalPrice - discountedPrice) / originalPrice) * 100;
  return parseFloat(disCountPercent.toFixed(2)); // Ensure precision
};

// Calculate Discounted Price
export const generateDiscountedPrice = (price, discount) => {
  if (isNaN(price) || isNaN(discount) || discount < 0 || discount > 100) return '₹0';
  const discountAmount = (discount / 100) * price;
  const discountedPrice = price - discountAmount;
  return displayMoney(discountedPrice); // Return formatted price
};

import React from 'react';
import category1 from '../../assets/CATEGORIES/category6.webp';
import category2 from '../../assets/CATEGORIES/category5.webp';
import category3 from '../../assets/CATEGORIES/Attar.jpeg';
import category4 from '../../assets/CATEGORIES/gift-set.jpeg';
import category5 from '../../assets/CATEGORIES/combo-set-2.jpeg';
import category6 from '../../assets/CATEGORIES/combo-set-3.jpeg';

const Category = () => {
  const collections = [
    { url: category1, altText: 'DEODORANTS', title: 'DEODORANTS ', link: '/products' },
    { url: category2, altText: 'PERFUMES', title: 'PERFUMES', link: '/products' },
    { url: category3, altText: 'ATTAR', title: 'ATTAR', link: '/products' },
    { url: category4, altText: 'GITFT SET', title: 'GITFT SET', link: '/products' },
    { url: category5, altText: 'COMBO SET 2', title: 'COMBO SET 2', link: '/products' },
    { url: category6, altText: 'COMBO SET 3', title: 'COMBO SET 3', link: '/products' },
  ];

  return (
    <div className="bg-[#001c25] text-white p-4 overflow-hidden">
      <h2 className="collections-title text-center mb-4 text-[#001c25] py-4">CATEGORY</h2>
      <div className="grid grid-cols-2 gap-1.5 md:grid-cols-3 lg:grid-cols-6"> {/* Updated gap */}
        {collections.map((collection, index) => (
          <a
            key={index}
            href={collection.link}
            className="block text-center p-0 m-0" // No padding or margin on the anchor tag
            style={{ margin: 0, padding: 0 }}  // Ensure no external space
          >
            <img
              className="w-full h-auto object-cover"
              src={collection.url}
              alt={collection.altText}
              loading="lazy"
              style={{ height: '150px', width: '100%', objectFit: 'cover', margin: 0, padding: 0 }}
            />
            <h3
              className="mt-2 text-[#c29436] font-bold text-lg uppercase"
              style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)', margin: '0 auto', padding: 0, width: 'fit-content' }}
            >
              {collection.title}
            </h3>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Category;

import React, { useState } from "react";
import ReorderIcon from "@mui/icons-material/Reorder";
import SearchBar from "./Searchbar";
import "./Header.css";
import CartIcon from "./CartIcon";
import { Link, useNavigate } from "react-router-dom"; // Updated import
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import ProfileModal from "./ProfileModel";

function Header() {
  const navigate = useNavigate(); // Updated to useNavigate
  const { isAuthenticated, user } = useSelector((state) => state.userData);

  const [searchBarActive, setSearchBarActive] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // Handle sideBar
  const handleSideBarMenu = () => {
    setSideMenu(!sideMenu);
  };

  // Toggle Search Button
  const handleSearchButtonClick = () => {
    setSearchBarActive(!searchBarActive);
  };

  // Input value of Search bar
  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  // Handle Search Form Submit
  const handleSearchFormSubmit = (event) => {
    event.preventDefault();
    if (searchValue.trim()) {
      navigate(`/products/${searchValue}`); // Updated to use navigate
    } else {
      navigate("/products"); // Updated to use navigate
    }
  };

  // Handle Search Bar Toggle Button
  const handleCrossButtonClick = () => {
    setSearchValue("");
    setSearchBarActive(!searchBarActive);
  };

  // Function to handle logo click
  const handleLogoClick = () => {
    window.location.href = "/"; // Redirect to home page and refresh
  };

  return (
    <>
      <div className="header">
        <div className="headerTop">
        <div className="headerTopLeft">
          <p className="text-center">We Offer Free Shipping!</p>
        </div>
          <div className="headerTopRight">
            <div className="headerRetailer">
              <span>
                <div className="marquee">
                  Limited Time Offer: Get 20% Off on All Orders! Order Now
                </div>
              </span>
            </div>
            <div className="headerLogin">
              {isAuthenticated ? (
                <Link
                  to="/account"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <button>My Account</button>
                </Link>
              ) : (
                <Link
                  to="/signup"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <button>Sign Up</button>
                </Link>
              )}
            </div>
          </div>
        </div>

        {/* Bottom Header */}
        <div className="headerBottom">
          <div className="headerBottom__logo">
            <div className="header_mobile_menu">
              <span>
                <ReorderIcon
                  onClick={handleSideBarMenu}
                  sx={{
                    fontSize: 29,
                    color: "black",
                    "&:hover": {
                      color: "#e7070f",
                      cursor: "pointer",
                    },
                  }}
                />
                {sideMenu && (
                  <Sidebar
                    handleSideBarMenu={handleSideBarMenu}
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                )}
              </span>
              <span>
                <SearchBar
                  searchBarActive={searchBarActive}
                  searchValue={searchValue}
                  handleCrossButtonClick={handleCrossButtonClick}
                  handleSearchButtonClick={handleSearchButtonClick}
                  handleSearchInputChange={handleSearchInputChange}
                  handleSearchFormSubmit={handleSearchFormSubmit}
                />
              </span>
            </div>
          </div>
          {!searchBarActive && (
            <div onClick={handleLogoClick}>
              <img
                src={require("../../../Image/logo.png")} // Consider using import for images
                alt="logo"
                className="headerBottom__logo_main"
              />
            </div>
          )}

          {/* navmenu */}
          {!searchBarActive && (
            <div className="headerBottom_navMenu">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/products">Product</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/about_us">About</Link>
                </li>
                <li>
                  <a href="https://bolgmufaworld.com/" target="_blank" rel="noopener noreferrer">Blog</a>
                </li>
              </ul>
            </div>
          )}

          {/* Icons */}
          <div className="headerBotttom_icons">
            <div className="search_Bar">
              <SearchBar
                searchBarActive={searchBarActive}
                searchValue={searchValue}
                handleCrossButtonClick={handleCrossButtonClick}
                handleSearchButtonClick={handleSearchButtonClick}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchFormSubmit={handleSearchFormSubmit}
              />
            </div>
            <span>
              <Link
                to="/cart"
                style={{ color: "none", textDecoration: "none" }}
              >
                <CartIcon />
              </Link>
            </span>
            <span>
              <ProfileModal user={user} isAuthenticated={isAuthenticated} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

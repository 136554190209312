import { useEffect } from "react";

const ScrollToTopOnRefresh = () => {
  useEffect(() => {
    // Disable scroll restoration and scroll to the top
    const { history } = window;

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
      window.scrollTo(0, 0);
    }

    return () => {
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'auto';
      }
    };
  }, []);

  return null; // No rendered output
};

export default ScrollToTopOnRefresh;

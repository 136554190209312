import React from "react";
import { Link } from "react-router-dom";
import MetaData from "../component/layouts/MataData/MataData";
import ScrollToTopOnRefresh from "../component/Home/ScrollToTop";

const About_UsPage = () => {
  return (
    <>
    <ScrollToTopOnRefresh />
      <div className="flex flex-col items-center justify-center py-16 bg-white w-full">
        <MetaData title={"About Us"} />

        {/* Main Content */}
        <div className="p-8 text-center max-w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center">
            {/* Image Section */}
            <div className="flex justify-center">
              <img
                src="https://res.cloudinary.com/difmu7prg/image/upload/v1729665744/about_fyto63.webp"
                alt="MufaWorld"
                className="w-full h-auto object-contain max-w-full md:max-w-2xl"
              />
            </div>

            {/* Text Section */}
            <div className="text-left md:pl-8">
              <h1 className="text-xl text-gray-800 font-bold mb-4">About Us</h1>
              <p className="text-justify text-gray-700 leading-relaxed mb-4">
                There was a small but ambitious company known as Mufa Perfumes
                founded in 2014, the team had a passion for creating the most
                seductive fragrances combining traditional techniques with
                modern innovations to create an exceptional experience for their
                customers and set out to create a perfume line that would leave
                a lasting impression. It began with an exclusive range of non
                alcoholic fragrances for the discerning tastes of people who
                appreciate the finer things in life.
              </p>
              <p className="text-justify text-gray-700 leading-relaxed">
                Over the years, Mufa Perfumes quickly gained an outstanding
                reputation in the realm of perfumery and evolved into Mufa
                International Pvt Ltd, the brand expanded its offerings to
                include alcohol-based perfumes and body deodorants focusing on
                quality and innovation. The company has continuously innovated
                and expanded its product line, from classic fragrances to modern
                blends, each scent is carefully crafted to meet the diverse
                needs of its customers which are not only pleasant, but also
                long-lasting and memorable.
              </p>
            </div>
          </div>
        </div>

        {/* Second Section: Who We Are */}
        <div className="p-6 text-center max-w-full">
          <h2 className="text-lg text-gray-800 font-bold mb-4">Who We Are</h2>
          <p className="text-justify text-gray-700 leading-relaxed mb-4">
            There was a small but ambitious company known as Mufa Perfumes
            founded in 2014, the team had a passion for creating the most
            seductive fragrances combining traditional techniques with modern
            innovations to create an exceptional experience for their customers
            and set out to create a perfume line that would leave a lasting
            impression. It began with an exclusive range of non alcoholic
            fragrances for to the discerning tastes of people who appreciate the
            finer things in life.
          </p>
          <p className="text-justify text-gray-700 leading-relaxed mb-4">
            The team at Mufa International Pvt Ltd is dedicated to the art of
            perfumery and is constantly searching for the best ingredients to
            experiment with new combinations and create new and unique
            fragrances that stand out from the rest. They believe that
            fragrances are an extension of personality and should reflect the
            essence of the person wearing them. Whether you are looking for a
            signature scent for every day or a special perfume for a special
            occasion, Mufa International Pvt Ltd has something for you. If you
            are focused on quality, innovation, and customer satisfaction or a
            business looking for a company to partner with to reach more
            customers around the world we are open to collaboration.
          </p>
          <p className="text-justify text-gray-700 leading-relaxed">
            If you are looking for a company that is passionate about fragrances
            and committed to excellence, look no further than Mufa International
            Pvt Ltd. With us, you can be sure that you will receive a fragrance
            that is not only of the highest quality, but also a true reflection
            of your personality.
          </p>
        </div>

        {/* Third Section: Our Mission */}
        <div className="px-6 pt-4 pb-4 text-center max-w-full">
          <h2 className="text-lg text-gray-800 font-bold mb-4">Our Mission</h2>
          <p className="text-justify text-gray-700 leading-relaxed mb-4">
            MufaWorld is driven by the mission to provide high-quality cricket
            equipment and accessories at affordable prices. We aim to make
            cricket accessible to players worldwide and support their passion
            for the sport. Our mission is to offer a wide range of cricket
            equipment, including bats, balls, protective gear, and accessories,
            that meet the highest standards of quality and performance.
          </p>
          <p className="text-justify text-gray-700 leading-relaxed mb-4">
            We are committed to continuously innovating and improving our
            product range to meet the evolving needs of cricketers. Our team of
            experts works closely with manufacturers and conducts rigorous
            quality testing to ensure that every product we offer delivers
            exceptional performance on the field. We believe that every player
            deserves the best tools to enhance their skills and achieve their
            cricketing goals.
          </p>

          {/* Buttons */}
          <div className="flex justify-center mt-4">
            <Link to="/products">
              <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-red-600 transition-all">
                Our Products
              </button>
            </Link>
            <Link to="/contact" className="ml-4">
              <button className="bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-all">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default About_UsPage;

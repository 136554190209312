import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "./FeatureSlider.css"; // Your custom CSS for styling
import { Link } from "react-router-dom";
import { displayMoney } from "../DisplayMoney/DisplayMoney";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../actions/cartAction"; // Import your add to cart action

const FeaturedSlider = ({ products }) => {
  const dispatch = useDispatch();

  // Handler for adding products to the cart
  const addToCartHandler = (productId, quantity) => {
    dispatch(addItemToCart(productId, quantity)); // Dispatching the action to add item to cart
  };

  return (
    <Swiper
      modules={[EffectCoverflow, Pagination, A11y, Autoplay]}
      loop={products.length > 1}
      speed={500}
      spaceBetween={150}
      slidesPerView={"auto"}
      pagination={{ clickable: true }}
      effect={"coverflow"}
      centeredSlides={true}
      coverflowEffect={{
        rotate: 0,
        stretch: 10,
        depth: 50,
        modifier: 3,
        slideShadows: false,
      }}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        768: {
          slidesPerView: 2,
          spaceBetween: 200,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 250,
        },
      }}
      className="featured_swiper"
    >
      {products.map((product) => {
        const { _id, images, name, price, DiscountPrice } = product;

        // Directly format prices (replace `displayMoney` with your own logic if needed)
        const formattedOldPrice = displayMoney(price);
        const formattedDiscountPrice = displayMoney(DiscountPrice);

        return (
          <SwiperSlide key={_id} className="featured_slides">
            <Link
              to={`/product/${_id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="featured_title">{name}</div>
              <figure className="featured_img">
                <img src={images[0].url} alt={name} />
              </figure>
              <h2 className="products_price">
                <span className="final_price">{formattedDiscountPrice}</span> &nbsp;
                <small>
                  <del className="old_price">{formattedOldPrice}</del>
                </small>
              </h2>
            </Link>
            <div className="card_overflow">
              <button
                className="bg-yellow-400 text-black w-full py-2 px-4 rounded-md hover:bg-yellow-500 transition-colors"
                onClick={() => addToCartHandler(product._id, 1)} // Call addToCartHandler on button click
              >
                Add to Cart
              </button>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default FeaturedSlider;

import React from 'react';

const Blog = () => {
    const posts = [
        { date: "03 March 2015", user: "Mufa Wolrd", title: "Fragrance Revolution: The Hottest Scents of This Year", content: "Elevate Your Beauty Routine: A Complete Guide to Clean Beauty, Cosmetic Enhancements, and Luxury Fragrances...", imageUrl: "https://cdn.easyfrontend.com/pictures/testimonial/testimonial_15.png" },
        { date: "07 August 2024", user: "Mufa Wolrd", title: "5-10 Reasons Why Mufa Deos Are the Best Long-Lasting Perfumes for Me", content: "Choosing the perfect perfume is a nuanced decision, particularly for men who seek longevity in their fragrance...", imageUrl: "https://res.cloudinary.com/difmu7prg/image/upload/v1728892886/Products/odyibjb9fp55qiiiyvbt.jpg" },
        { date: "03 October 2024", user: "Mufa Wolrd", title: "Long-Lasting Deodorant? Unveiling Mufa Allure Deo’s Staying Power", content: "The world of fragrance is constantly evolving, with innovative scents captivating consumers in new and exciting ways...", imageUrl: "https://res.cloudinary.com/difmu7prg/image/upload/v1728891606/Products/arharrzkml0zenqwotzy.jpg" }
    ];

    return (
        <div>
            <h2 className="blog-title">FRAGRANCE JOURNEYS</h2>
            <div className="blog-section">
                <div className="container">
                    <div className="posts">
                        {posts.map((post, index) => (
                            <div className="post" key={index}>
                                <img src={post.imageUrl} alt={post.title} className="post-image" />
                                <div className="post-content">
                                    <h3>{post.title}</h3>
                                    <p>{post.date} / {post.user}</p>
                                    <p>{post.content}</p>
                                    <a href="https://bolgmufaworld.com/" className="read-more" target="_blank" 
                                        rel="noopener noreferrer">READ MORE</a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;

import React, { useEffect, useRef, useState } from "react";
import {
  Dashboard as DashboardIcon,
  ShoppingCart as ShoppingCartIcon,
  Assignment as AssignmentIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  LockOpen as LockOpenIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { Modal, Avatar } from "@mui/material";
import "./ProfileModel.css";
import { useNavigate } from "react-router-dom"; // Updated to useNavigate
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/userAction";

const ProfileModal = ({ user, isAuthenticated }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Updated to useNavigate
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);

  const formatDate = (user) => {
    const createdAt = new Date(user.createdAt);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    return new Intl.DateTimeFormat("en-IN", options).format(createdAt);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOpen = (event) => {
    event.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const navigateTo = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  const logoutUserHandler = () => {
    setIsOpen(false);
    dispatch(logout());
    alert.success("Logout Successfully");
  };

  return (
    <>
      <div className="profile-icon" onClick={handleOpen}>
        <PersonIcon
          className={`icon smaller ${isOpen ? "active" : ""}`}
          fontSize="large"
        />
        {isOpen ? (
          <ArrowDropUpIcon className="arrow-icon" />
        ) : (
          <ArrowDropDownIcon className="arrow-icon" />
        )}
      </div>
      {isOpen && (
        <Modal open={isOpen} onClose={onClose} className="modal-container">
          <div className="modal-content" ref={modalRef}>
            {!isAuthenticated ? (
              <div className="welcome-message">
                <strong>Welcome!</strong>
                <p>To access your account and manage orders, please log in.</p>
              </div>
            ) : (
              <div className="profile-info">
                <Avatar
                  src={user.avatar.url}
                  alt="User Avatar"
                  className="avatar"
                  sx={{ width: 68, height: 68 }}
                />
                <p className="user-id">
                  <strong>ID :</strong> {user._id.substring(0, 8)}
                </p>
                <p className="user-name">
                  <strong>Name :</strong> {user.name}
                </p>
                <p className="user-email">
                  <strong>Email :</strong> {user.email}
                </p>
                <p className="created-at">
                  <strong>Joined at:</strong> {formatDate(user)}
                </p>
              </div>
            )}
            <div className="divider" />
            <div className="profile-menu">
              {user && user.role === "admin" && (
                <div
                  className="menu-item"
                  onClick={() => navigateTo("/admin/dashboard")}
                >
                  <DashboardIcon className="menu-icon" />
                  <span>Dashboard</span>
                </div>
              )}
              <div className="menu-item" onClick={() => navigateTo("/account")}>
                <AccountCircleIcon className="menu-icon" />
                <span>Profile</span>
              </div>
              <div className="menu-item" onClick={() => navigateTo("/orders")}>
                <AssignmentIcon className="menu-icon" />
                <span>Orders</span>
              </div>
              <div className="menu-item" onClick={() => navigateTo("/cart")}>
                <ShoppingCartIcon className="menu-icon" />
                <span>Cart</span>
              </div>
              {!isAuthenticated ? (
                <div className="menu-item" onClick={() => navigateTo("/login")}>
                  <LockOpenIcon className="menu-icon" />
                  <span>Login</span>
                </div>
              ) : (
                <div className="menu-item" onClick={logoutUserHandler}>
                  <ExitToAppIcon className="menu-icon" />
                  <span>Logout</span>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProfileModal;

import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const videos = [
  'https://res.cloudinary.com/difmu7prg/video/upload/v1728971999/mufa-perfume-video1_czonbn.mp4',
  'https://res.cloudinary.com/difmu7prg/video/upload/v1728972016/mufa-perfume-video4_umzojg.mp4',
  'https://res.cloudinary.com/difmu7prg/video/upload/v1728972010/mufa-perfume-video5_yrsjb0.mp4',
  'https://res.cloudinary.com/difmu7prg/video/upload/v1728972010/mufa-perfume-video6_qcnhdb.mp4'
  // 'https://res.cloudinary.com/difmu7prg/video/upload/v1728972009/mufa-perfume-video3_wor70i.mp4',
  // 'https://res.cloudinary.com/difmu7prg/video/upload/v1728972016/mufa-perfume-video2_nngfwg.mp4'
];

const VideoSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const handleClick = (index) => {
    setClickedIndex(index);
  };

  const handleCloseClick = () => {
    setClickedIndex(null);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % videos.length);
    }, 10000); // Change video every 10 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleVideoError = (index, error) => {
    console.error(`Error loading video at index ${index}:`, error);
  };

  return (
    <div className="py-8 text-center bg-[#001c25] video-section">
      <h2 className="section-title text-white mb-6">
        MUFA FRAGRANCE x TRENDING
      </h2>
      <div className="flex flex-wrap gap-4 justify-center">
        {videos.map((video, index) => (
          <div 
            key={index} 
            className="video-wrapper relative w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(index)}
          >
            {clickedIndex === index ? (
              <div className="video-player-wrapper relative">
                <ReactPlayer
                  url={video}
                  playing
                  loop
                  muted
                  width="100%"
                  height="100%"
                  onClick={(e) => e.stopPropagation()} // Prevent closing on click inside the player
                  onError={(e) => handleVideoError(index, e)}
                />
                <button className="close-button absolute top-0 right-0 bg-red-500 text-white p-1" onClick={handleCloseClick}>
                  Close
                </button>
              </div>
            ) : (
              <ReactPlayer
                url={video}
                playing={index === activeIndex}
                loop
                muted
                width="100%"
                height="100%"
                onError={(e) => handleVideoError(index, e)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoSection;

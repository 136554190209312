import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Home.css';
import PerfumeTg from '../../assets/homeimg/best-perfumes-for-man combo-deodrant.webp';

const SevenSection = () => {
  return (
    <Container>
      <Row className="pt-8 pb-8">
        <Col>
          <div className="zoom-out text-[#dec27e] pl-3" style={{ fontSize: '2.5rem', fontWeight: 700 }}>
            Long Lasting Mufa Perfume: Experience Freshness All Day
          </div>
        </Col>
        <Col>
          <img
            className="zoom-out sizee"
            src={PerfumeTg}
            alt="Red Label Mufa Perfume"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SevenSection;
